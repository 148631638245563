import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Training.css';
import img1 from './Training/riego.jpg';
import img2 from './Training/fertilidad.jpg'
import img3 from './Training/mecanizacion-inteligente.jpg'

const Training = () => {
  const trainings = [
    {
      id: 1,
      title: "Curso de Riego",
      text: "El curso abarca desde sistemas de riego hasta la gestión del cambio climático, bajo una perspectiva de uso de herramientas digitales. Este curso no solo enseña técnicas de riego eficiente sino que también prepara a los agricultores para adoptar prácticas digitales que mejoren la sostenibilidad de sus operaciones. Al finalizar, los participantes reciben certificación que respalda su habilidad para integrar estas tecnologías en sus prácticas diarias, fomentando un uso más racional y eficiente del agua.",
      imgSrc: img1,
      link: "https://smartfieldinia.cl/campus/course/2/about"
    },
    {
      id: 2,
      title: "Curso de Fertilidad",
      text: "Dirigido a profesionales del sector agrícola, este curso aborda la nutrición de cultivos, frutales y vides, enseñando a identificar y tratar problemas nutricionales. Los participantes aprenderán a reconocer los signos de deficiencia y exceso de nutrientes y a aplicar soluciones efectivas para optimizar la salud y productividad de los cultivos. Este conocimiento práctico y teórico es esencial para promover una agricultura más saludable y productiva.",
      imgSrc: img2,
      link: "https://smartfieldinia.cl/campus/course/6/about"
    },
    {
      id: 3,
      title: "Curso de Mecanización Inteligente",
      text: "Este curso ofrece una introducción integral a las últimas tecnologías en agricultura, desde principios físicos básicos, pasando la operación de maquinaria avanzada hasta el uso de drones y tecnologías de precisión. Los participantes explorarán cómo estas innovaciones pueden transformar las prácticas agrícolas, haciéndolas más eficientes y sostenibles. A través de ejemplos reales y análisis de casos, el curso destaca la importancia de la robotización y otras tecnologías emergentes en la mejora de la productividad y la gestión de recursos en la agricultura moderna.",
      imgSrc: img3,
      link: "https://smartfieldinia.cl/campus/course/7/about"
    }
  ];

  return (
    <Container fluid className="training-container">
      <Row>
        <Col md={12}>
          <h1 className="training-main-title">Capacitaciones virtuales</h1>
          <p className="training-main-text">Los cursos de riego, fertilidad y mecanización inteligente introduce a los participantes en un entorno de aprendizaje vanguardista que simula un metaverso, proporcionando una plataforma interactiva.</p>
        </Col>
      </Row>
      {trainings.map(training => (
        <Row key={training.id} className="training-row">
          <Col md={12}>
            <Card className="training-card">
              <Row className="no-gutters">
                <Col md={4} className="training-image-col">
                  <Card.Img src={training.imgSrc} alt={training.title} className="training-image" />        
                </Col>
                <Col md={8} className="training-content-col">
                  <Card.Body>
                    <Card.Title className="training-title">{training.title}</Card.Title>
                    <Card.Text className="training-text">{training.text}</Card.Text>
                    <Button variant="success cap-button" className="training-button" href={training.link} target="_blank">Más Información</Button>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Training;