import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import './NavBar.css'; // Importar para tener el borde, opciones personalizadas de CSS
import navCorfo from './NavBar/corfoNav.png';
import navInia from './NavBar/iniaNav.png';

const NavBar = () => {
  return (
    <Navbar className="bg-custom" variant="dark" expand="lg">
      <LinkContainer to="/">
        <Navbar.Brand className="navbar-brand-custom textNav">
          <img src={navInia} alt="INIA" className="nav-logo" />
          <img src={navCorfo} alt="Corfo" className="nav-logo" />
          <span className="text-agro textNav">AGRO</span>
          <span className="text-conecta textNav">CONECTA</span>
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <LinkContainer to="/nosotros">
            <Nav.Link className='textNav'>Nosotros</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/proyectos">
            <Nav.Link className='textNav'>Unidades Demostrativas</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/campus">
            <Nav.Link className='textNav'>Campus</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/eventos">
            <Nav.Link className='textNav'>Eventos</Nav.Link>
          </LinkContainer>
          <Nav.Link href="mailto:sbest@inia.cl?subject=Contacto%20de%20Agroconecta" className='textNav'>
            Contacto
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
