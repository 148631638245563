// Contacto, esto controla todos los componentes que se integran en la pagina Contacto
import React from 'react';
import FormContact from '../components/Contacto/FormContact';
import Map from '../components/Contacto/Map';
import Footer from '../components/Footer';

const Contacto = () => {
  return (
    <div>
      <FormContact />
      <Map />
      <Footer/>
    </div>
  );
};

export default Contacto;
