// Nosotros/Intro.js
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import intro from "./Intro/intro.jpg";
import "./Intro.css";

const Intro = () => {
  return (
    <Container>
      <h1 className="text-center introtitle">
      <span className="agro-intro">AGRO</span>
      <span className="conecta-intro">CONECTA</span>
      </h1>
      <div style={{ margin: '30px 0' }}> {/* Agregar espacio entre cada componente */}</div>
      <Row>
        <Col md={6}>
          <Card className="mb-3" style={{ height: "100%" }}>
            <Card.Body className="card-intro">
              <Card.Title className="intro-text">
                Nuestro compromiso es con el agricultor chileno, a quien
                ofrecemos las herramientas y el conocimiento necesario para
                mejorar su productividad y eficiencia. Al mismo tiempo, estamos
                comprometidos con la preservación del medio ambiente y la
                implementación de prácticas agrícolas que respeten y potencien
                los recursos naturales. AgroConecta es posible gracias al apoyo
                de organismos como INIA y CORFO, y a la colaboración de socios
                estratégicos que comparten nuestra visión de una agricultura más
                inteligente y conectada. Juntos, estamos construyendo un
                ecosistema que no solo responde a las necesidades actuales, sino
                que también prepara el terreno para las generaciones futuras de
                agricultores chilenos.
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card style={{ height: "100%" }}>
            <Card.Img variant="top" src={intro} style={{ height: "100%" }} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Intro;

