// Formulario de Contacto
import React, { useState } from 'react';

const FormContact = () => {
  const [form, setForm] = useState({
    nombre: '',
    apellido: '',
    email: '',
    asunto: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica para enviar el email
    console.log(form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Nombre:</label>
        <input type="text" name="nombre" value={form.nombre} onChange={handleChange} />
      </div>
      <div>
        <label>Apellido:</label>
        <input type="text" name="apellido" value={form.apellido} onChange={handleChange} />
      </div>
      <div>
        <label>Email:</label>
        <input type="email" name="email" value={form.email} onChange={handleChange} />
      </div>
      <div>
        <label>Asunto:</label>
        <input type="text" name="asunto" value={form.asunto} onChange={handleChange} />
      </div>
      <div>
        <label>Mensaje:</label>
        <textarea name="mensaje" value={form.mensaje} onChange={handleChange} />
      </div>
      <button type="submit">Enviar</button>
    </form>
  );
};

export default FormContact;
