// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Nosotros from './pages/Nosotros';
import Proyectos from './pages/Proyectos';
import Campus from './pages/Campus';
import Eventos from './pages/Eventos';
import Contacto from './pages/Contacto';
import Footer from './components/Footer';
import LoginPost from './pages/LoginPost';
import Publish from './pages/Publish'; // Asegúrate de importar Publish

function App() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const savedPosts = JSON.parse(localStorage.getItem('posts')) || [];
    setPosts(savedPosts);
  }, []);

  useEffect(() => {
    localStorage.setItem('posts', JSON.stringify(posts));
  }, [posts]);

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/proyectos" element={<Proyectos />} />
        <Route path="/campus" element={<Campus />} />
        <Route path="/eventos" element={<Eventos posts={posts} />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/LoginPost" element={<LoginPost posts={posts} setPosts={setPosts} />} />
        <Route path="/wiodSFgHGjasDadu469832485736475" element={<Publish posts={posts} setPosts={setPosts} />} />
      </Routes>
    </Router>
  );
}

export default App;
