// Nosotros, esto controla todos los componentes que se integran en la pagina Nosotros
import React from 'react';
import Intro from '../components/Nosotros/Intro';
import Verticales from '../components/Nosotros/Verticales';
import BannerRoute from '../components/Nosotros/BannerRoute';
import Footer from '../components/Footer';

const Nosotros = () => {
  return (
    <div>
      <div style={{ margin: '40px 0' }}> {/* Agregar espacio entre cada componente */}</div>
      <Intro />
      <div style={{ margin: '40px 0' }}> {/* Agregar espacio entre cada componente */}</div>
      <Verticales/>
      <BannerRoute/>
      <Footer/>
    </div>
  );
};

export default Nosotros;
