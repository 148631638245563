import React from 'react';
import './BannerRoute.css';
import banner from './BannerRoute/Banner.svg'

const BannerRoute = () => {
    return (
    <div>
        <img  className="banner-design" src={banner}></img>
    </div>
  );
};

export default BannerRoute;
