import React from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import './Testimony.css';

// Importar Clips de Testimonios
import testimony1 from './Testimony/clip1.mp4';

// Array con los datos del carrusel de testimonios
const testimonies = [
  {
    videoSrc: testimony1,
  },
  // Agrega más elementos según sea necesario
];

const Testimony = () => {
  return (
    <div id="Testimony" className='testimonyBG'>
      <Container>
        <Row style={{ margin: '40px 0' }}></Row>
        <h1 className='titleTesmony'>Testimonios</h1>
        <Row style={{ margin: '20px 0' }}></Row>
        <Carousel>
          {testimonies.map((item, index) => (
            <Carousel.Item className='carouselTestimony' interval={10000} key={index}>
              <Row>
                <Col md={12}>
                  <video controls width="100%" className='videoTestimony'>
                    <source src={item.videoSrc} type="video/mp4" />
                    No es posible reproducir este video.
                  </video>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Testimony;