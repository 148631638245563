// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaLinkedin, FaInstagram, FaFacebook, FaGlobe } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="footer">
      <Container>
        <Row>

          <Col md={6}>
            <div className="footer-content">
              <h3 className='titleFooter'>Agroconecta</h3>
              <p className='subtitleFooter'>Proyecto en desarrollo en Los Ángeles, Biobío.</p>
              <Row style={{ margin: '20px 0' }}> {/* Agregar espacio entre cada componente */}</Row>

              <p>Email de Contacto: </p>
              <Row style={{ margin: '-10px 0' }}> {/* Agregar espacio entre cada componente */}</Row>
              <a href="mailto:sbest@inia.cl" className="contact-button emailFooter">
              sbest@inia.cl
              </a>
            </div>
          </Col>

          <Col md={6} className="text-end">
            <div className="footer-links">
              <p className='subtitleFooter'>Plataformas Digitales</p>
              <div>
              <Button variant="link" href="https://smartfieldinia.cl/" target="_blank">
                <FaGlobe /> Web smartfield
              </Button>
              </div>
              <Row style={{ margin: '-5px 0' }}> {/* Agregar espacio entre cada componente */}</Row>
              <div>
              <Button variant="link" href="https://www.linkedin.com/company/smartfield-inia/" target="_blank">
                <FaLinkedin /> LinkedIn
              </Button>
              </div>
              <Row style={{ margin: '-5px 0' }}> {/* Agregar espacio entre cada componente */}</Row>
              <div>
              <Button variant="link" href="https://www.instagram.com/smartfieldinia/" target="_blank">
                <FaInstagram /> Instagram
              </Button>
              </div>
              <Row style={{ margin: '-5px 0' }}> {/* Agregar espacio entre cada componente */}</Row>
              <div>
              <Button variant="link" href="https://www.facebook.com/smartfieldinia" target="_blank">
                <FaFacebook /> Facebook
              </Button>
              </div>
              
              <Row style={{ margin: '20px 0' }}> {/* Agregar espacio entre cada componente */}</Row>
              
              <p className='subtitleFooter'>Plataforma del Campus</p>
              <Button variant="link" href="https://smartfieldinia.cl/campus/" target="_blank">
                <FaGlobe /> Campus
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;

//Crearlink de Youtube www.youtube.com/@smartfieldinia