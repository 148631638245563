import React from "react";
import { Carousel, Button } from "react-bootstrap";
import "./CarouselComponent.css";

//Importa imagenes
import img1 from "./Carousel/img1.jpg";
import img4 from "./Carousel/img4.png";

// Array con los datos del carrusel
const carouselItems = [
  {
    image: img1,
    text: "Habilitando la digitalización del sector agrícola, a través de la capacitación y transferencia de tecnologías digitales.",
    // Sin botón
  },
  {
    image: img4,
    text: "El Seminario de Inauguración de AgroConecta, celebrado el 24 de mayo de 2024, destacó estrategias para la digitalización agrícola en Chile. Con expertos de INACAP, CORFO e INIA, y una exposición de tecnologías por empresas colaboradoras al proyecto, el evento enfatizó la colaboración para una agricultura avanzada.",
    buttonText: 'Ver Mas',
    buttonLink: 'https://www.linkedin.com/feed/update/urn:li:activity:7201252930539188224 '
  },
  // Agrega más items según sea necesario
];

const CarouselComponent = () => {
  return (
    <Carousel>
      {carouselItems.map((item, index) => (
        <Carousel.Item className="introhome-intro" key={index}>
          <img
            className="d-block w-100"
            src={item.image}
            alt={`Slide ${index + 1}`}
          />
          <Carousel.Caption className="introhome-caption">
            <p className="introhome-paragraph">{item.text}</p>
            {item.buttonText && item.buttonLink && (
              <div className="introhome-buttons">
                <Button
                  variant="success introhome-boton1"
                  href={item.buttonLink}
                >
                  {item.buttonText}
                </Button>
              </div>
            )}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;

/*

{
  image: img1,
  text: 'Te llevamos nuestro SmartField para que visites las tecnologías en un formato 360º también puedes agendar una visita guiada.',
  buttonText: 'Más Información',
  buttonLink: '/'
},

*/
