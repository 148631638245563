// Proyectos, esto controla todos los componentes que se integran en la pagina Proyectos
import React from 'react';
import CardProject from '../components/Proyectos/CardProject';
import Footer from '../components/Footer';

const Proyectos = () => {
  return (
    <div>
      <div style={{ margin: '20px 0' }}> {/* Agregar espacio entre cada componente */}</div>
      <CardProject />
      <Footer />
    </div>
  );
};

export default Proyectos;
