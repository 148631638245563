// Home, esto controla todos los componentes que se integran en la pagina principal
import React from 'react';
import CarouselComponent from '../components/Home/CarouselComponent';
import Cards from '../components/Home/Cards';
import Testimony from '../components/Home/Testimony';
import About from '../components/Home/About';
import Company from '../components/Home/Company';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <CarouselComponent />
      <div style={{ margin: '20px 0' }}> {/* Agregar espacio entre cada componente */}</div>
      <Cards />
      <div style={{ margin: '40px 0' }}> {/* Agregar espacio entre cada componente */} </div>
      <About/>
      <Company/>
      <Testimony />
      <Footer/>
    </div>
  );
};

export default Home;
