// src/pages/LoginPost.js
import React, { useState } from 'react';
import Login from './Login';
import Publish from './Publish';

const LoginPost = ({ posts, setPosts }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <div>
      {isAuthenticated ? (
        <Publish posts={posts} setPosts={setPosts} />
      ) : (
        <Login onLogin={() => setIsAuthenticated(true)} />
      )}
    </div>
  );
};

export default LoginPost;
