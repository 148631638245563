import React from 'react';
import { Container, Row, Col, Card, Carousel, Button } from 'react-bootstrap';
import './CardProject.css';

// Imágenes de CE-Human
import ceh1 from './CardProject/CE-HUMAN/project1.avif';
import ceh2 from './CardProject/CE-HUMAN/project2.avif';
import ceh3 from './CardProject/CE-HUMAN/project3.avif';
import ceh4 from './CardProject/CE-HUMAN/project4.avif';
import ceh5 from './CardProject/CE-HUMAN/HumanCE1.png'

import cehimg1 from './CardProject/CE-HUMAN/HumanCE1.png'

// Imágenes de HORTIFRUT
import horti3 from './CardProject/HORTIFRUT/hortifrut3.png'

// Imágenes de INDEF
import indef1 from './CardProject/INDEF/indef1.JPG';
import indef2 from './CardProject/INDEF/indef2.JPG';
import indef3 from './CardProject/INDEF/indef3.JPG';
import indef4 from './CardProject/INDEF/indef4.JPG';
import indef5 from './CardProject/INDEF/indef5.JPG';
import indef6 from './CardProject/INDEF/indef6.JPG';
import indef7 from './CardProject/INDEF/indef7.JPG';
import indef8 from './CardProject/INDEF/indef8.JPG';
import indef9 from './CardProject/INDEF/indef9.JPG';

// Imagenes de UNIKIWI
import kiwi1 from './CardProject/UNIKIWI/unikiwi1.png'
import kiwi2 from './CardProject/UNIKIWI/unikiwi2.jpeg'

// Imágenes de ORIEL
import oriel4 from './CardProject/ORIEL/oriel4.JPG'
import oriel5 from './CardProject/ORIEL/oriel5.JPG'

// Imágenes de RUFF
import ruff1 from './CardProject/RUFF/Ruff1.png'

const CardProject = () => {
  const projects = [
    {
      id: 1,
      title: "Agrícola Oriel Jara",
      subtitle: "Cultivos",
      text: "Los visitantes pueden explorar tecnologías avanzadas en riego, fertilidad y fitosanitario, diseñadas para optimizar la eficiencia y sostenibilidad de las operaciones agrícolas tradicionales. Este campo actúa como vitrina demostrativa, ofreciendo una visión práctica del impacto positivo de estas tecnologías en la gestión de recursos y cultivos.",
      imgSrc: [oriel4, oriel5],
    },
    {
      id: 2,
      title: "Fundo Tucuman",
      subtitle: "Frutales y cultivos",
      text: "Presenta una integración única de tecnologías en riego, fertilidad y fitosanitario, bajo un enfoque de agroecología. Este módulo destaca cómo la tecnología puede coexistir con prácticas agrícolas sostenibles y es especialmente relevante para la agricultura familiar campesina, facilitando la transferencia de conocimientos a asesores de INDAP.",
      imgSrc: [ruff1],
    },
    {
      id: 3,
      title: "Hortifrut S.A.",
      subtitle: "Frutales",
      text: "Se centra en la mejora de prácticas fitosanitarias mediante la demostración de tecnologías que perfeccionan la calibración y dosificación en tratamientos de cultivos. Este enfoque no solo mejora la calidad y rendimiento de los frutos sino que también refuerza la colaboración con proveedores, fortaleciendo su cadena de suministro y competitividad en el mercado.",
      imgSrc: [horti3],
    },
    {
      id: 4,
      title: "INDEF Agrícola Limitada",
      subtitle: "Frutales",
      text: "Adopta tecnologías en riego, fertilidad y fitosanidad. El encargado predial, activamente interesado en capacitaciones en estos campos, facilita la expansión de estas tecnologías a través de una red de productores asociados en un grupo GTT, promoviendo su adopción en el contexto local.",
      imgSrc: [indef1, indef2, indef3, indef4, indef5, indef6, indef7, indef8, indef9],
    },
    {
      id: 5,
      title: "C. E. Human, Los Ángeles, INIA",
      subtitle: "Cultivo",
      text: "El Centro Experimental Human demuestra el uso de tecnologías en riego, fertilidad y fitosanitario, mostrando cómo estas pueden incrementar la eficiencia y sostenibilidad en la agricultura. Con un campo dedicado a demostraciones, este sitio sirve como un modelo para la implementación efectiva de nuevas prácticas y tecnologías en el manejo de cultivos tradicionales, bajo un esquema de riego por pivote central automatizado.",
      imgSrc: [ceh5],
    },
    {
      id: 6,
      title: "Agricola UNIKIWI",
      subtitle: "Frutales",
      text: "Agrícola UNIKIWI se especializa en la demostración de tecnologías avanzadas para el manejo eficiente del riego en cultivos de frutales. Esta unidad demuestra la capacidad de las tecnologías modernas para integrarse con prácticas agrícolas sostenibles, optimizando el uso del agua y mejorando la productividad y sostenibilidad de las operaciones agrícolas.",
      imgSrc: [kiwi1, kiwi2],
    },
  ];

  const handleContactClick = (title) => {
    window.location.href = `mailto:sbest@inia.cl?subject=${encodeURIComponent(title)}`;
  };

  return (
    <Container>
      <Row>
        <Col>
          <div style={{ margin: '10px 0' }}></div>
          <h1 className="title-project">Unidades Demostrativas</h1>
          <div style={{ margin: '40px 0' }}></div>
        </Col>
      </Row>
      <Row>
        {projects.map(project => (
          <Col md={6} key={project.id} className="mb-4">
            <Card className="project-card">
              {project.imgSrc.length > 1 ? (
                <Carousel className='carousel-ud'>
                  {project.imgSrc.map((img, index) => (
                    <Carousel.Item className='carruselproject' key={index}>
                      <img className="d-block w-100" src={img} alt={`Slide ${index + 1}`} />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <Card.Img className='cardimage' variant="top" src={project.imgSrc[0]} />
              )}
              <Card.Body className="card-body">
                <Card.Title className="project-title">{project.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted project-sub">{project.subtitle}</Card.Subtitle>
                <Card.Text className="project-text">{project.text}</Card.Text>
                <Button variant="success" onClick={() => handleContactClick(project.title)}>Contactar</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CardProject;
