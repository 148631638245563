import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Image, Carousel, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Linkify from 'react-linkify';
import './Publish.css'; // Importamos el archivo CSS

const Publish = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [images, setImages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    axios.get('http://localhost:5000/api/posts')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching posts:', error);
      });
  }, []);

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handlePublish = () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    images.forEach(image => {
      formData.append('images', image);
    });

    if (editId) {
      // Update existing post
      axios.put(`http://localhost:5000/api/posts/${editId}`, formData)
        .then(response => {
          setPosts(posts.map(post => post.id === editId ? response.data : post));
          resetForm();
        })
        .catch(error => {
          console.error('Error updating post:', error);
        });
    } else {
      // Create new post
      axios.post('http://localhost:5000/api/upload', formData)
        .then(response => {
          setPosts([response.data, ...posts]);
          resetForm();
        })
        .catch(error => {
          console.error('Error uploading post:', error);
        });
    }
  };

  const handleDelete = (id) => {
    axios.delete(`http://localhost:5000/api/posts/${id}`)
      .then(() => {
        setPosts(posts.filter(post => post.id !== id));
      })
      .catch(error => {
        console.error('Error deleting post:', error);
      });
  };

  const handleEdit = (id) => {
    const post = posts.find(post => post.id === id);
    setTitle(post.title);
    setContent(post.content);
    setImages([]); // Reset images, new ones can be added
    setEditId(id); // Set edit mode
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setImages([]);
    setEditId(null);
  };

  return (
    <Container>
      <h2>{editId ? 'Editar Publicación' : 'Publicar'}</h2>
      <Form>
        <Form.Group controlId="postTitle">
          <Form.Label>Título de la publicación</Form.Label>
          <Form.Control
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="postContent" className="mt-3">
          <Form.Label>Contenido de la publicación</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="postImages" className="mt-3">
          <Form.Label>Imágenes de la publicación</Form.Label>
          <Form.Control
            type="file"
            multiple
            accept="image/jpeg, image/jpg" // Acepta solo archivos JPG
            onChange={handleImageChange}
          />
        </Form.Group>
        <Button variant="primary" onClick={handlePublish} className="mt-3">
          {editId ? 'Actualizar' : 'Publicar'}
        </Button>
        {editId && (
          <Button variant="secondary" onClick={resetForm} className="mt-3 ms-2">
            Cancelar
          </Button>
        )}
      </Form>
      <h2 className="mt-5">Historial de Publicaciones</h2>
      {posts.map(post => (
        <Card className="mt-3" key={post.id}>
          <Row>
            <Col md={4}>
              {post.images.length > 1 ? (
                <Carousel className="carousel-publish">
                  {post.images.map((img, index) => (
                    <Carousel.Item key={index}>
                      <div className="image-publish">
                        <Image src={`http://localhost:5000${img}`} thumbnail />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <div className="image-publish">
                  <Image src={`http://localhost:5000${post.images[0]}`} thumbnail />
                </div>
              )}
            </Col>
            <Col md={8}>
              <Card.Body>
                <Card.Title className='titlepost'>{post.title}</Card.Title>
                <Card.Text className='textpost'>
                  <Linkify>{post.content}</Linkify>
                </Card.Text>
                <Button variant="danger" onClick={() => handleDelete(post.id)}>
                  Eliminar Publicación
                </Button>
                <Button variant="secondary" onClick={() => handleEdit(post.id)} className="ms-2">
                  Editar Publicación
                </Button>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      ))}
    </Container>
  );
};

export default Publish;
