import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './About.css';
import wall1 from './About/wall1.jpg';

const About = () => {

  return (
    <div className="about-container" style={{ backgroundImage: `url(${wall1})` }}>
      <div className="overlay">
        <Container>
          <h1 className='titulo-about'>Sobre Nosotros</h1>
          <p className='parrafo-about'>Bienvenidos a AgroConecta, su puente hacia la agricultura del futuro en Chile. Somos un proyecto innovador que busca revolucionar el sector agrícola a través de la digitalización y la adopción de tecnologías AgTech avanzadas. Nuestra misión es conectar la oferta y la demanda de soluciones tecnológicas, fomentando la competitividad y sostenibilidad de la agricultura chilena. En AgroConecta, trabajamos de la mano con expertos en tecnología, instituciones académicas, y líderes de la industria para implementar soluciones que aborden directamente los desafíos del sector. Desde unidades demostrativas en el campo hasta capacitaciones virtuales y presenciales, nuestro enfoque es práctico y orientado a resultados.</p>
          <Link to="/nosotros">
            <Button variant="success" className='color-button'>Ir a Nosotros</Button>
          </Link>
        </Container>
      </div>
    </div>
  );
};

export default About;