import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Verticales.css"; // Importa el archivo CSS para los estilos personalizados
import img1 from "./Verticales/img1.png";
import img2 from "./Verticales/img2.png";

const Verticales = () => {
  return (
    <Container>
      <Row>
        <h1 className="title-verticales">Verticales del proyecto</h1>
      </Row>
      <div style={{ margin: '10px 0' }}></div> {/* Agregar espacio entre cada componente */}
      <Row>
        <Col md={6}>
          <Card className="vertical-card mb-3">
            <Card.Img variant="top" src={img1} />
            <Card.Body>
              <Card.Title className="verticales-title">Unidades demostrativas</Card.Title>
              <Card.Subtitle className="mb-2 text-muted verticales-sub">
                Innovación aplicada
              </Card.Subtitle>
              <Card.Text className="verticales-text">
                Las unidades demostrativas de AgroConecta permiten a los
                agricultores experimentar en vivo cómo las tecnologías AgTech
                optimizan procesos y mejoran rendimientos. Estos espacios,
                diseñados para mostrar el uso práctico de innovaciones,
                facilitan la adopción de nuevas soluciones al proporcionar una
                comprensión clara y directa de sus beneficios.
              </Card.Text>
              <Link to="/proyectos">
                <Button variant="success" className="button-cfg">Ver más</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="vertical-card">
            <Card.Img variant="top" src={img2} />
            <Card.Body>
              <Card.Title className="verticales-title">Capacitaciones virtuales</Card.Title>
              <Card.Subtitle className="mb-2 text-muted verticales-sub">
                Cursos prácticos
              </Card.Subtitle>
              <Card.Text className="verticales-text">
                Las capacitaciones virtuales de AgroConecta brindan instrucción
                detallada sobre tecnologías AgTech, facilitando el acceso remoto
                para agricultores y técnicos. Estas sesiones interactivas y
                prácticas promueven la adopción de nuevas herramientas y métodos
                para optimizar la producción agrícola y aumentar la
                sostenibilidad y eficiencia del sector.
              </Card.Text>
              <Link to="/campus">
                <Button variant="success" className="button-cfg">Ver más</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Verticales;
