import React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Cards.css';

const Cards = () => {
  return (
    <Container className="cards-container">
      <Row>
        <Col md={4}>
          
          <Card className="custom-card">
            <Card.Body>
              <Card.Title className="custom-card-title">Testimonios</Card.Title>
              <Card.Text className='custom-card-text'>
              AgroConecta promueve la digitalización y sostenibilidad en la agricultura chilena con tecnologías AgTech, ofreciendo capacitaciones y demostraciones para cerrar brechas tecnológicas y mejorar la competitividad del sector.
              </Card.Text>
              
              <Button variant="success" href="#Testimony" className="custom-button">Ir a los Testimonios</Button>
              
            </Card.Body>
          </Card>

        </Col>
        <Col md={4}>

          <Card className="custom-card">
            <Card.Body>
              <Card.Title className="custom-card-title">Capacitaciones virtuales</Card.Title>
              <Card.Text className='custom-card-text'>
              Las capacitaciones virtuales de AgroConecta ofrecen formación interactiva en tecnologías AgTech, accesibles a distancia, permitiendo a agricultores y técnicos mejorar sus prácticas agrícolas y adoptar soluciones innovadoras eficazmente.
              </Card.Text>
              <Link to="/campus">
              <Button variant="success" className="custom-button">Ir a las Capacitaciones virtuales</Button>
              </Link>
            </Card.Body>
          </Card>

        </Col>
        <Col md={4}>

          <Card className="custom-card">
            <Card.Body>
              <Card.Title className="custom-card-title">Unidades demostrativas</Card.Title>
              <Card.Text className='custom-card-text'>
              Las unidades demostrativas de AgroConecta exhiben tecnologías AgTech en un entorno real, mostrando su aplicación práctica para educar y motivar a agricultores y técnicos hacia la adopción tecnológica efectiva.
              </Card.Text>
              <Link to="/proyectos">
              <Button variant="success"  className="custom-button">Ir a las Unidades Demostrativas</Button>
              </Link>
            </Card.Body>
          </Card>

        </Col>
      </Row>
    </Container>
  );
};

export default Cards;
