// src/pages/Eventos.js
import React from 'react';
import Footer from '../components/Footer';
import AgroconectaPosts from '../components/Eventos/Events';

const Eventos = () => {
  return (
    <div>
      <AgroconectaPosts/>
      <Footer/>
    </div>
  );
};

export default Eventos;