import React from 'react';
import { Carousel } from 'react-bootstrap';
import './Company.css';

import img1 from './Company/Logo1.png';
import img2 from './Company/Logo2.png';
import img3 from './Company/Logo3.jpeg';
import img4 from './Company/Logo4.png';
import img5 from './Company/Logo5.png';
import img6 from './Company/Logo6.png';
import img7 from './Company/Logo7.png';
import img8 from './Company/Logo8.png';
import img9 from './Company/Logo9.jpeg';
import img10 from './Company/Logo10.png';
import img11 from './Company/Logo11.png';
import img12 from './Company/Logo12.png';
import img13 from './Company/Logo13.png';
import img14 from './Company/Logo14.png';
import img15 from './Company/Logo15.png';

const images = [
  [img1, img2, img3, img4, img5],
  [img6, img7, img8, img9, img10],
  [img11, img12, img13, img14, img15],
];

const CarouselComponent = () => {
  return (
    <div className="company-carousel-container">
      <div style={{ margin: '40px 0' }}> {/* Agregar espacio entre cada componente */} </div>
      <h1 className="text-center">Empresas Colaboradoras</h1>
      <Carousel className="company-carousel">
        {images.map((group, groupIndex) => (
          <Carousel.Item className="carousel-card" key={groupIndex}>
            <div className="d-flex justify-content-around">
              {group.map((image, index) => (
                <img
                  className="logo-image"
                  key={index}
                  src={image}
                  alt={`Image ${groupIndex * 5 + index + 1}`}
                  style={{ maxWidth: '30%', height: 'auto' }}
                />
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
