// Campus, esto controla todos los componentes que se integran en la pagina Campus
import React from 'react';
import Training from '../components/Campus/Training';
import Footer from '../components/Footer';

const Campus = () => {
  return (
    <div>
      <Training />
      <Footer/>
    </div>
  );
};

export default Campus;
